import {mapActions, mapGetters, mapMutations} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import mainSelect from '@/components/main-select/index.vue';
import {formatCurrency} from "../../../../../utils/commons";
import moment from 'moment';
import Da from "vue2-datepicker/locale/es/da";
//sections
export default {
  name: "dress-card-table",
  components: {
    mainSelect,
    DatePicker,
  },
  props: {
    dress: {
      type: Object,
      default: ()=> {},
    },
    deletable: {
      type: Boolean,
      default: ()=> false,
    },
    last: {
      type: Boolean,
      default: ()=> false,
    }
  },
  data() {
    return {
      sizeOptions: [],
      trainOptions: [
        {
          value: false,
          title: 'Without train',
        },
        {
          value: true,
          title: 'With train',
        },
      ],
      countOptions: [],
    }
  },
  created() {
    this.sizeOptions = this.dress.sizes.data.map(el=>{
      return {
        value: el.id,
        title: `EUR ${el.size}`
      }
    })
    this.countOptions = Array.from({ length: 100 }, (_, i) => ({ title: i + 1, value: i + 1 }));
    this.dress.payload.size = this.sizeOptions[0]
    this.dress.payload.train = this.trainOptions[0]
    this.dress.payload.count = this.countOptions[0]
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      profile: 'profile/user',
      addToCartLoading: 'collections/addDressToCartLoading'
    }),
    dressDiscount(){
      if(this.dress.hasSalePriceWithTrain && this.dress.payload.train.value){
        return '—'
      } else if(this.dress.hasSalePriceWithoutTrain && !this.dress.payload.train.value){
        return '—'
      } else {
        return `${this.profile.salePercentage ? this.profile.salePercentage : 0}%`
      }
    },
    selectedConfig(){
      const selectedSize = this.dress.sizes.data.find(el => el.id === this.dress.payload.size.value)
      return {
        withoutTrain: selectedSize.priceWithoutTrain,
        withoutTrainWithDiscount: selectedSize.priceWithoutTrainWithDiscount,
        withTrain: selectedSize.priceWithTrain,
        withTrainWithDiscount: selectedSize.priceWithTrainWithDiscount,
        totalWithDiscount: this.dress.payload.train.value ? selectedSize.priceWithTrainWithDiscount * this.dress.payload.count.value : selectedSize.priceWithoutTrainWithDiscount * this.dress.payload.count.value,
        total: this.dress.payload.train.value ? selectedSize.priceWithTrain * this.dress.payload.count.value : selectedSize.priceWithoutTrain * this.dress.payload.count.value,
      }
    }
  },
  mounted() {

  },
  methods: {
    formatCurrency,
    ...mapActions({
    }),
    disablePastDates(date) {
      const today = new Date();
      return date < today;
    },
    handleAddDress(){
      this.$emit('change-add')
    },
    handleDeleteItem(){
      this.$emit('change-delete')
    },
    handleAddToCart(){
      this.$emit('change-cart')
    }
  }
}